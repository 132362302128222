<template>
  <div>
    <filter-bar name="users" :filters="filters"  @reset="reset"  @filtrer="filtrer" @search="search"  :idWharhouse="idWharhouse"
      :currentUser="currentUser" @searchRoles="searchRoles"></filter-bar>
    <div class="min-h-screen flex-1 bg-gray-100 px-3 items-center">
      <div class="bg-white w-fullrounded-lg shadow overflow-hidden">
        <div class="h-20 py-3 flex items-center border-gray-200 p-6">
          <div class="flex">
            <div class="text-2xl font-bold text-green-700">{{$t('users')}}</div>
          </div>
          <div class="w-full flex justify-end">
            <router-link
              class="flex rounded focus:outline-none outline-none text-white px-3 w-1/7 py-2 bg-green-600  hover:shadow-inner ml-4 hover:bg-green-600  transition-all duration-300"
              to="/users/new"
            >
              <i class="material-icons">add</i> {{$t('add_user')}}
            </router-link>
          </div>
        </div>
        <div class="overflow-x-auto">
          <base-table
            :refresh="refresh"
            :content="content"
            :headers="headers"
            :idWharhouse="idWharhouse"
            :currentUser="currentUser"
            name="users"
          >
          </base-table>
        </div>
         <div class="p-1">
          <div class="flex flex-col items-center my-12" :class="paginate.lastpage">
            <div v-if="paginate.lastpage > 1"  class="flex text-gray-700">
              <button
                v-if="paginate.currentpage > 1"
                @click="prev"
                :disabled="paginate.currentpage == 1"
                class="h-8 w-8 focus:outline-none mr-6 flex justify-center text-green-700    items-center rounded-full bg-gray-200 hover:bg-green-600  hover:text-white cursor-pointer"
              >
                <i class="material-icons"> keyboard_arrow_left </i>
              </button>
              <div class="flex h-8 font-medium rounded-full bg-gray-200">
                <div
                  v-if="paginate.currentpage != paginate.lastpage"
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full bg-green-600  text-white"
                >
                  {{ paginate.currentpage }}
                </div>
                <div
                  v-else
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  <span v-if="paginate.currentpage != 1"> 1 </span>
                </div>

                <div
                  v-if="paginate.currentpage >= 1 && paginate.lastpage != 1"
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  ...
                </div>

                <button
                  :class="
                    paginate.currentpage == paginate.lastpage
                      ? 'bg-green-600  text-white'
                      : ' '
                  "
                  class="w-8 md:flex justify-center items-center hover:bg-green-600  hover:text-white focus:outline-none hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  {{ paginate.lastpage }}
                </button>
              </div>
              <button
                v-if="paginate.currentpage < paginate.lastpage"
                :disabled="paginate.currentpage == paginate.lastpage"
                @click="next"
                class="h-8 w-8 ml-6 focus:outline-none flex justify-center text-green-700    items-center rounded-full bg-gray-200 hover:bg-green-600  hover:text-white cursor-pointer"
              >
                <i class="material-icons"> keyboard_arrow_right </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() {
    return {
      content: [],
      headers: ["fullName","company","source", "phone", "email", "type","services", "countries", "status"],
      users: [],
      statusUser: ["active", "inactive", "blocked","deleted"],
      typeUser:  [],
      limit: 10,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
      filter:{},
      filterss:{},
      warehouses:[],
     // idWharhouse:null,
      //currentUser:{},
      filters: {
            name: 'users',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "FullName, username, phone, email ..."
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [{
                        label: "Role",
                        key: "role",
                        name: "name",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                   {
                        label: "Status",
                        key: "status",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                ],
            },
      },
    };
  },
  async mounted() {
   // await this.getUser();
     if(this.$f.QueryStringToobject()) this.filterss=this.$f.QueryStringToobject();
     else this.$f.objectToQueryString({limit:this.limit})
    // await this.getWarehouses();
    // await this.getwharhouseId();
    if(this.filterss.name) await this.search(this.filterss.name);
    else await this.getUsers(this.filterss);
    await this.getRoles();
    this.setDataSelect("role", this.typeUser, null);
    this.setDataSelect("status", this.statusUser, null);
  },
   computed: {
        warhouseSelected(){
            return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
          },
   },
   watch: {
      'warhouseSelected': async function(oldVal, newVal) {
         //await this.getwharhouseId();
         //await this.reset();
      },
      'idWharhouse': async function(oldVal, newVal) {
            this.idWharhouse=oldVal;  
            await this.reset();
        }
  },
  methods: {
    async getUser() {
      const res = await this.$server.me("users");
      //console.log(res);
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
      console.log('currentUser__fff____________',this.currentUser)
    },
    async getwharhouseId(){
        await this.$store.dispatch('wharhouse/getwharhouseId',{country:await this.warhouseSelected}).then((res) => {
                this.idWharhouse=res;
            })
    },
    async getWarehouses(){
        const filter = {limit:10,is_primary:true};
        const res = await this.$server.search("warehouses",filter);
        if (res.content.results) {
           this.warehouses = res.content.results;
        } else this.warehouses = [];
    }, 
     setDataSelect(model, data, splice) {
      for (var i = 0; i < this.filters.elements.champs.length; i++) {
        if (
          this.filters.elements.champs[i].type === "select" &&
          this.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.filters.elements.champs[i].values = data;
          } else {
            this.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
     getElementInArray(data,value,table){
        console.log('data---------',data)
           console.log('value-----',value)
        var result  = data.filter(function(o){
         
             let whar;
             if(table==="warehouses") whar=o._id.toString();
             else whar=o.warehouse?o.warehouse.toString():'';
            return whar == value.toString();
        } );
        return result? result[0] : null; // or undefined
    },
    /*********************************** Users  ***************************************/
    async getUsers(filters) {
      filters['countries']=await this.warhouseSelected;
      const res = await this.$server.search("users",filters);

      if (res.content.results) {
        this.content = res.content.results.filter(function(item) { return item.fullName !== 'system'; });
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
        for (let i in this.content) {
            const dataWhrahouseSetting= this.getElementInArray(this.content[i].settings,this.idWharhouse._id,"settings");
            if(dataWhrahouseSetting) {
                   dataWhrahouseSetting.flag=this.idWharhouse.flag;
                   this.content[i].oldObject={settings:[dataWhrahouseSetting]};
              }
            for (let j in this.content[i].countries) {
              if(this.content[i].countries[j]) this.content[i].countries[j]=this.$f.getCountryCode(this.content[i].countries[j]);
            }
        }
      } else{ this.content = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}}
      console.log('content---------------',this.content)
    },
    async getRoles() {
      const res = await this.$server.search("roles");
      console.log('res.content.results',res.content.results)
       if (res.content.results) {
        this.typeUser = res.content.results;
      } else{ this.typeUser = [];}
    },
    searchRoles: _.debounce(async function(search,event) {
       const filter = {
        limit:10,
        name: search,
      };
      const res= await this.$server.find("roles",filter);

     if (res.content.results) this.typeUser = res.content.results;
     else this.typeUser = [];
      this.setDataSelect("role", this.typeUser, null);
    }, 500),
    async filtrer(data) {
       if(this.$f.QueryStringToobject()) this.filterss=this.$f.QueryStringToobject();
       delete  this.filterss.page;
       delete  this.filterss.name;
       if(!this.filterss['limit']) this.filterss['limit']=this.limit;

       if(data.role) this.filterss.role=data.role._id;else delete this.filterss.role;
        if(data.status) this.filterss.status=data.status;else delete this.filterss.status;
       this.paginate.currentpage=1;
        this.$f.objectToQueryString(this.filterss)
      await this.getUsers(this.filterss);
    },
    async refresh() {
      if(this.$f.QueryStringToobject()) this.filterss=this.$f.QueryStringToobject();
      this.$f.objectToQueryString(this.filterss)
      if(this.filterss.name) await this.search(this.filterss.name);
      else await this.getUsers(this.filterss);
      if(this.filterss['page'] && this.filterss['page']>1 && this.content.length==0){
          this.filterss['page']= --this.filterss['page'];
          this.$f.objectToQueryString(this.filterss)
          await this.getUsers(this.filterss);
       }
    },
    async reset(data) {
      let lmitt=this.limit;
      if(this.$f.QueryStringToobject()) this.filterss=this.$f.QueryStringToobject();
      if(this.filterss['limit']) lmitt=this.filterss['limit'];
      this.filterss['page']=1;
      this.$f.objectToQueryString({limit:lmitt})
      await this.getUsers({limit:lmitt});
      },
    async search(data) {
       this.paginate= {total: 0,currentpage: 1,lastpage: 1,per_page: 0,};
        let limitt=this.limit
       if(this.filters['limit']) limitt=this.filterss['limit'];

      const search = {
        name: data.trim(),
        limit:parseInt(limitt),
        countries:await this.warhouseSelected
      };
      this.$f.objectToQueryString(search)
      const res= await this.$server.find("users",search);

        if (res.content.results.length>0) {
        this.content = res.content.results.filter(function(item) { return item.fullName !== 'system'; });
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
         for (let i in this.content) {
            const dataWhrahouseSetting= this.getElementInArray(this.content[i].settings,this.idWharhouse._id,"settings");
            if(dataWhrahouseSetting) {
                   dataWhrahouseSetting.flag=this.idWharhouse.flag;
                   this.content[i].oldObject={settings:[dataWhrahouseSetting]};
            }
            for (let j in this.content[i].countries) {
              if(this.content[i].countries[j]) this.content[i].countries[j]=this.$f.getCountryCode(this.content[i].countries[j]);
            }
           
        }
      } else{ this.content = []}
    },
      async next() {
  
      if(this.$f.QueryStringToobject()) this.filterss=this.$f.QueryStringToobject();
      this.filterss['page']=++this.paginate.currentpage;
      this.$f.objectToQueryString(this.filterss)
     
      await this.getUsers(this.filterss);
    },
    async prev() {

     if(this.$f.QueryStringToobject()) this.filterss=this.$f.QueryStringToobject();
      this.filterss['page']=--this.paginate.currentpage;
      this.$f.objectToQueryString(this.filterss)

      await this.getUsers(this.filterss);
    },
  },
};
</script>

<style scoped>
  .vs__dropdown-toggle {
    border: none;
    }   
</style>




